<template>
  <div>
    <!-- 列表展示区 -->
    <l-table-common
      :data-source="tableData"
      :pagination="false"
    >
      <l-table-column title="入口">
        <template
          slot-scope="scope"
        >
          {{ puzzleTypeStr[scope.puzzleType + ''] || '' }}
        </template>
      </l-table-column>

      <l-table-column
        title="图片名称"
        data-index="name"
      />

      <l-table-column title="操作">
        <template
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="openDetail(scope)"
          >
            <div>
              详情
            </div>
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示结束区 -->
    <!--详情弹跳框 -->
    <l-modal
      v-model="isShowAiPuzzle"
      title="详情信息"
      :width="600"
      @ok="savePicture"
    >
      <div class="p-ai-puzzle_container">
        <div class="p-ai-puzzle_bag">
          <div class="p-ai-puzzle_bag-text">图片名称 :</div>
          <l-input v-model="form.name" class="p-ai-puzzle_bag-input" placeholder="请输入" />
        </div>
        <div class="p-ai-puzzle_bag">
          <div class="p-ai-puzzle_bag-text">上传图片 :</div>
        </div>
        <div v-if="!form.id">
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">简体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListAcross"
                :multiple="true"
                @change="handleChange($event,'zhcn','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListUpright"
                :multiple="true"
                @change="handleChange($event,'zhcn','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">繁体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListZhtwAcross"
                :multiple="true"
                @change="handleChange($event,'zhtw','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListZhtwUpright"
                :multiple="true"
                @change="handleChange($event,'zhtw','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">英文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListEnAcross"
                :multiple="true"
                @change="handleChange($event,'en','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListEnUpright"
                :multiple="true"
                @change="handleChange($event,'en','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
        </div>
        <div v-if="form.id">
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">简体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorZhcn" />
                </template>
                {{ form.resourceNameHorZhcn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorZhcn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorZhcn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListAcross"
                :multiple="true"
                @change="handleChange($event, 'zhcn','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerZhcn" />
                </template>
                {{ form.resourceNameVerZhcn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerZhcn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerZhcn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListUpright"
                :multiple="true"
                @change="handleChange($event, 'zhcn','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>

          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">繁体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorZhtw" />
                </template>
                {{ form.resourceNameHorZhtw }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorZhtw" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorZhtw)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListZhtwAcross"
                :multiple="true"
                @change="handleChange($event, 'zhtw','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerZhtw" />
                </template>
                {{ form.resourceNameVerZhtw }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerZhtw" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerZhtw)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListZhtwUpright"
                :multiple="true"
                @change="handleChange($event, 'zhtw','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>

          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">英文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorEn" />
                </template>
                {{ form.resourceNameHorEn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorEn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorEn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListEnAcross"
                :multiple="true"
                @change="handleChange($event, 'en','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerEn" />
                </template>
                {{ form.resourceNameVerEn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerEn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerEn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListEnUpright"
                :multiple="true"
                @change="handleChange($event, 'en','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
        </div>
      </div>
    </l-modal>
    <!-- 详情 结束区 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowAiPuzzle: false, // 是否展示详情弹窗
      form: { // 新建案例弹窗缓存数据和详情弹跳窗数据缓存区
        name: '', // 储存轮播图列表名字
        type: '', // 类型, video、image
        id: '', // 储存列表主键
        resourceNameHorEn: '', // 横屏英文资源名称
        resourceNameHorZhcn: '',
        resourceNameHorZhtw: '',
        resourceNameVerEn: '', // 竖屏英文资源名称
        resourceNameVerZhcn: '',
        resourceNameVerZhtw: '',
        resourceUrlHorEn: '', // 横屏英文资源url
        resourceUrlHorZhcn: '',
        resourceUrlHorZhtw: '',
        resourceUrlVerEn: '', // 竖屏英文资源url
        resourceUrlVerZhcn: '',
        resourceUrlVerZhtw: '',
      },
      tableData: [], // 列表渲染数据
      sessionKey: sessionStorage.getItem('sessionKey'),
      fileListAcross: [], // 新增和修改图片按钮上传路径缓存区
      fileListUpright: [],
      fileListZhtwAcross: [],
      fileListZhtwUpright: [],
      fileListEnAcross: [],
      fileListEnUpright: [],
      puzzleTypeStr: {
        '1': '拼图',
        '2': '拼字'
      }
    }
  },
  mounted() {
    this.showList(1)
  },
  methods: {
    // 展示列表-------------------
    async showList(page) {
      const data = await this.$store.dispatch('aiPuzzleManage/aiPuzzleManageList', {
        page: page,
        size: this.tableData.size
      })
      if (data.count === 0) { // 列表没有数据时名字显示默认，否则显示该数据名字
        this.tableData = [{ name: '默认' }]
      } else {
        this.tableData = data.list || []
      }
    },
    // 展示列表结束区-------------
    // 点击详情触发
    async openDetail(data) {
      this.form = JSON.parse(JSON.stringify(data))
      this.fileListAcross = [] // 把本地图片缓存清空
      this.fileListUpright = []
      this.fileListZhtwAcross = []
      this.fileListZhtwUpright = []
      this.fileListEnAcross = []
      this.fileListEnUpright = []
      if (!this.form.id) {
        this.form = this.$options.data().form
      }
      this.isShowAiPuzzle = true
    },
    // 详情弹窗的确认按钮
    async savePicture() {
      // （没传入id）增加或（传入id）修改
      await this.$store.dispatch('aiPuzzleManage/aiPuzzleManageSave', {
        id: this.form.id,
        name: this.form.name,
        resourceNameHorEn: this.form.resourceNameHorEn,
        resourceNameHorZhcn: this.form.resourceNameHorZhcn,
        resourceNameHorZhtw: this.form.resourceNameHorZhtw,
        resourceNameVerEn: this.form.resourceNameVerEn,
        resourceNameVerZhcn: this.form.resourceNameVerZhcn,
        resourceNameVerZhtw: this.form.resourceNameVerZhtw,
        resourceUrlHorEn: this.form.resourceUrlHorEn,
        resourceUrlHorZhcn: this.form.resourceUrlHorZhcn,
        resourceUrlHorZhtw: this.form.resourceUrlHorZhtw,
        resourceUrlVerEn: this.form.resourceUrlVerEn,
        resourceUrlVerZhcn: this.form.resourceUrlVerZhcn,
        resourceUrlVerZhtw: this.form.resourceUrlVerZhtw,
      })
      this.showList(1)
      this.isShowAiPuzzle = false
    },

    // 获取上传图片的文件数据
    handleChange(info, type, ststus) {
      // 下面三行连续代码是限制上传图片的数量
      if (type === 'zhcn') {
        if (ststus === 'across') {
          let fileListAcross = [...info.fileList]
          fileListAcross = fileListAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListAcross = fileListAcross
        } else {
          let fileListUpright = [...info.fileList]
          fileListUpright = fileListUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListUpright = fileListUpright
        }

      } else if (type === 'zhtw') {
        if (ststus === 'across') {
          let fileListZhtwAcross = [...info.fileList]
          fileListZhtwAcross = fileListZhtwAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListZhtwAcross = fileListZhtwAcross
        } else {
          let fileListZhtwUpright = [...info.fileList]
          fileListZhtwUpright = fileListZhtwUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListZhtwUpright = fileListZhtwUpright
        }

      } else {
        if (ststus === 'across') {
          let fileListEnAcross = [...info.fileList]
          fileListEnAcross = fileListEnAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListEnAcross = fileListEnAcross
        } else {
          let fileListEnUpright = [...info.fileList]
          fileListEnUpright = fileListEnUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListEnUpright = fileListEnUpright
        }

      }

      if (info.file.status === 'done') {
        const data = info.file.response && info.file.response.object
        if (!data) { // 如果上传的代码图片不符合要求，终止图片的上传进数据库，并把本地图片缓存清空
          this.$message.error(info.file.response && info.file.response.message)// 提示报错不符合要求的图片
          if (type === 'zhcn') {
            if (ststus === 'across') {
              this.fileListAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListUpright = []
            }
          } else if (type === 'zhtw') {
            if (ststus === 'across') {
              this.fileListZhtwAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListZhtwUpright = []
            }
          } else {
            if (ststus === 'across') {
              this.fileListEnAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListEnUpright = []
            }
          }
          return
        }
        if (type === 'zhcn') {
          if (ststus === 'across') {
            this.form.resourceNameHorZhcn = data.resourceName
            this.form.resourceUrlHorZhcn = data.resourceUrl
            // 压缩过长的名字
            this.form.resourceNameHorZhcn = this.$utils.cutFront(this.form.resourceNameHorZhcn)
          } else {
            this.form.resourceNameVerZhcn = data.resourceName
            this.form.resourceUrlVerZhcn = data.resourceUrl
            this.form.resourceNameVerZhcn = this.$utils.cutFront(this.form.resourceNameVerZhcn)
          }
        } else if (type === 'zhtw') {
          if (ststus === 'across') {
            this.form.resourceNameHorZhtw = data.resourceName
            this.form.resourceUrlHorZhtw = data.resourceUrl
            this.form.resourceNameHorZhtw = this.$utils.cutFront(this.form.resourceNameHorZhtw)
          } else {
            this.form.resourceNameVerZhtw = data.resourceName
            this.form.resourceUrlVerZhtw = data.resourceUrl
            this.form.resourceNameVerZhtw = this.$utils.cutFront(this.form.resourceNameVerZhtw)
          }
        } else {
          if (ststus === 'across') {
            this.form.resourceNameHorEn = data.resourceName
            this.form.resourceUrlHorEn = data.resourceUrl
            this.form.resourceNameHorEn = this.$utils.cutFront(this.form.resourceNameHorEn)
          } else {
            this.form.resourceNameVerEn = data.resourceName
            this.form.resourceUrlVerEn = data.resourceUrl
            this.form.resourceNameVerEn = this.$utils.cutFront(this.form.resourceNameVerEn)
          }
        }
      }
    },
    // 获取后端数据库图片数据的存放路径
    downLoadImg(url) {
      this.$store.dispatch('download', { url })
    },
  },

}
</script>

<style lang='scss'>
@import "@/assets/styles/aiPuzzleViewpager.scss";
</style>
